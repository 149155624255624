export default {
  home: "主页",
  kpi: "KPI管理",
  kpiScore: "KPI得分",
  kpiAppraisal: "个人绩效考评表",
  deptAssessment: "部门考核汇总表",
  event: "事件管理",
  eventPublish: "事件发布",
  eventHistory: "发布历史与流程详情",
  value: "价值观管理",
  valueList: "价值观列表",
  valueDeptCommon: "部门审批流程",
  valueHrCommon: "人事审批流程",
  valueDeptDetail: "部门价值观详情",
  performance: "业绩管理",
  targetList: "指标列表",
  performanceApproval: "业绩审批流程",
  performanceDeptDetail: "部门业绩详情",
  user: "用户管理",
  userList: "用户列表",
  log: "日志管理",
  logList: "操作日志",
  logDoc: "日志文件",
  toDoList: "待办事项",
  performanceConfirm: "个人绩效确认",
  wirteValue: "填写价值观",
  approvalVale: "审批价值观",
  //   approvalPerformance: "审批业绩",
  bounsDetail: "奖金系数详情",
  kpiDetail: "KPI得分详情",
  valueDetail: "价值观详情",
  inputTarget: "录入指标",
  addData: "补充历史数据",

  //管理员主页
  title: "绩效",
  addEvent: "新增事件",
  addEventText: "点击新增事件",
  eventProgress: "事件进度",
  bouns120: "奖金系数≥120%人员",
  valueState: "价值观状态",
  bouns: "奖金系数",
  KPIScore: "KPI得分",
  valueScore: "价值观",
  year: "年",
  month: "月",
  checkDetail: "查看详情",

  //按钮
  refresh: "刷新",
  add: "新增",
  inquire: "查询",
  save: "保存",
  delete: "删除",
  templet: "模板",
  lastTemplet: "上一月模板",
  import: "导入",
  export: "导出",
  release: "发布",
  downBouns: "奖金系数报表",
  downPerformance: "年度绩效汇总表",
  print: "打印",
  confirm: "确定",
  cancle: "取消",
  downPDF: "下载PDF",
  check: "查看",

  //表格字段
  dept: "部门",
  userName: "姓名",
  index: "序号",
  realScore: "实际得分",
  target: "业绩指标",
  score: "分数",
  performanceScore: "业绩",
  operate: "操作",

  //表单字段
  state: "状态",
  noWrite: "未填写",
  noFinish: "未完成",
  finish: "已完成",
  yes: "是",
  choose: "请选择",
  input: "请输入",
  signed: "已签名",
  noSign: "未签名",

  //描述
  previewPersonal: "预览个人绩效月考评表",
};
