import Layout from "@/system-views/logistics/LayoutIndex.vue";

const Back = [
  {
    path: "/admin",
    name: "Admin",
    component: Layout,
    meta: {
      requireAuth: true,
      visiable: true,
      roles: ["ROOT", "HR"],
      info: "菜单",
      keepAlive: false,
      icon: "ForkSpoon",
    },
    children: [
      {
        path: "/admin/menu",
        name: "",
        // component: () => import("@/system-views/logistics/menu/MenuIndex.vue"),
        meta: {
          requireAuth: true,
          visiable: true,
          roles: ["ROOT", "HR"],
          info: "菜谱",
          keepAlive: false,
          icon: "ForkSpoon",
        },
        children: [
          {
            path: "/admin/menu/week",
            name: "MenuWeek",
            component: () => import("@/system-views/logistics/menu/WeeklyMenu.vue"),
            meta: {
              requireAuth: true,
              visiable: true,
              roles: ["ROOT", "HR"],
              info: "制作",
              keepAlive: false,
              // icon: "ForkSpoon",
            },
          },
          {
            path: "/admin/menu/historical",
            name: "Historical",
            // component: () => import("@/system-views/logistics/data/HistoricalRecipes.vue"),
            meta: {
              requireAuth: true,
              visiable: true,
              roles: ["ROOT", "HR"],
              info: "数据",
              keepAlive: false,
            },
            children: [
              {
                path: "/admin/menu/historical",
                name: "HistoricalRecipes",
                component: () => import("@/system-views/logistics/data/HistoricalRecipes.vue"),
                meta: {
                  requireAuth: true,
                  visiable: true,
                  roles: ["ROOT", "HR"],
                  info: "历史菜谱",
                  keepAlive: false,
                },
              },
            ],
          },
          {
            path: "/admin/menu/count",
            name: "Count",
            // component: () => import("@/system-views/logistics/data/HistoricalRecipes.vue"),
            meta: {
              requireAuth: true,
              visiable: true,
              roles: ["ROOT", "HR"],
              info: "统计",
              keepAlive: false,
            },
            children: [
              {
                path: "/admin/menu/countSpiciness",
                name: "CountSpiciness",
                component: () => import("@/system-views/logistics/count/CountSpiciness.vue"),
                meta: {
                  requireAuth: true,
                  visiable: true,
                  roles: ["ROOT", "HR"],
                  info: "辣度",
                  keepAlive: false,
                },
              },
              {
                path: "/admin/menu/frequency",
                name: "CountFrequency",
                meta: {
                  requireAuth: true,
                  visiable: true,
                  roles: ["ROOT", "HR"],
                  info: "频次",
                  keepAlive: false,
                },
                children: [
                  {
                    path: "/admin/menu/countDishes",
                    name: "CountDishes",
                    component: () => import("@/system-views/logistics/count/CountDishes.vue"),
                    meta: {
                      requireAuth: true,
                      visiable: true,
                      roles: ["ROOT", "HR"],
                      info: "菜品",
                      keepAlive: false,
                    },
                  },
                ],
              },
            ],
          },
          {
            path: "/admin/menu/list",
            name: "FinishDishes",
            component: () => import("@/system-views/logistics/dishes/FinishDishes.vue"),
            meta: {
              requireAuth: true,
              visiable: true,
              roles: ["ROOT", "HR"],
              info: "成品菜",
              keepAlive: false,
              // icon: "ForkSpoon",
            },
            // children: [
            //   {
            //     path: "/admin/menu/meat",
            //     name: "MeatDishes",
            //     component: () => import("@/system-views/logistics/dishes/MeatDishes.vue"),
            //     meta: {
            //       requireAuth: true,
            //       visiable: true,
            //       roles: ["ROOT", "HR"],
            //       info: "荤菜",
            //       keepAlive: false,
            //     },
            //   },
            //   {
            //     path: "/admin/menu/vegetable",
            //     name: "VegetableDish",
            //     component: () => import("@/system-views/logistics/dishes/VegetableDish.vue"),
            //     meta: {
            //       requireAuth: true,
            //       visiable: true,
            //       roles: ["ROOT", "HR"],
            //       info: "素菜",
            //       keepAlive: false,
            //     },
            //   },
            //   {
            //     path: "/admin/menu/soup",
            //     name: "SoupList",
            //     component: () => import("@/system-views/logistics/dishes/SoupList.vue"),
            //     meta: {
            //       requireAuth: true,
            //       visiable: true,
            //       roles: ["ROOT", "HR"],
            //       info: "汤菜",
            //       keepAlive: false,
            //     },
            //   },
            //   {
            //     path: "/admin/menu/side",
            //     name: "SideDishes",
            //     component: () => import("@/system-views/logistics/dishes/SideDishes.vue"),
            //     meta: {
            //       requireAuth: true,
            //       visiable: true,
            //       roles: ["ROOT", "HR"],
            //       info: "配菜",
            //       keepAlive: false,
            //     },
            //   },
            //   {
            //     path: "/admin/menu/fruit",
            //     name: "FruitList",
            //     component: () => import("@/system-views/logistics/dishes/FruitList.vue"),
            //     meta: {
            //       requireAuth: true,
            //       visiable: true,
            //       roles: ["ROOT", "HR"],
            //       info: "水果",
            //       keepAlive: false,
            //     },
            //   },
            // ],
          },
          {
            path: "/admin/menu/rawMaterials",
            name: "RawMaterials",
            component: () => import("@/system-views/logistics/materials/RawMaterials.vue"),
            meta: {
              requireAuth: true,
              visiable: true,
              roles: ["ROOT", "HR"],
              info: "原材料表",
              keepAlive: false,
              // icon: "ForkSpoon",
            },
          },
          {
            path: "/admin/menu/key",
            name: "KeyList",
            component: () => import("@/system-views/logistics/key/KeyList.vue"),
            meta: {
              requireAuth: true,
              visiable: true,
              roles: ["ROOT", "HR"],
              info: "关键字表",
              keepAlive: false,
              // icon: "ForkSpoon",
            },
          },
        ],
      },
    ],
  },
];

export default Back;
